<template>
  <div id="header">
    <!-- 头部 -->
    <div class="head">
      <div class="logo">
        <img src="@/assets/botLogo.png" alt="" />
      </div>
      <!-- 导航菜单 -->
      <div class="menu">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">产品信息</el-menu-item>
          <el-menu-item index="3">关于我们</el-menu-item>
          <el-menu-item index="4">联系我们</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前选中的导航
      activeIndex: "1",
    };
  },
  computed: {},
  methods: {
    // 点击选择的导航菜单
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.$store.commit('updateSection', key[0]);
    },
  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
#header :deep() {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0,.8);
  z-index: 9999;
  width: 100%;
  // 头部
  .head {
    padding: 0 100px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 200px;
      img{
        width: 200px;
      }
    }
    // 导航菜单
    .menu {
      .el-menu {
        height: 100px;
        border: 0;
        background-color: transparent;
      }
      .el-menu-item {
        font-weight: normal;
        min-width: 130px;
        height: 100%;
        line-height: 100px;
        color: white;
        font-size: 16px;
        text-align: center;
      }
      // 当前选中菜单样式
      .el-menu--horizontal > .el-menu-item.is-active,
      .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
        border: 0px;
        font-weight: bold;
        color: #333333;
        background-color: #ff9b26;
        position: relative;
      }
      // 取消菜单hover效果
      .el-menu-item:hover,
      .el-menu-item:focus {
        color: #333333;
        background-color: #ff9b26;
        transition: 1s;
      }
      .el-menu--horizontal > .el-submenu:focus .el-submenu__title,
      .el-menu--horizontal > .el-submenu:hover .el-submenu__title {
        color: #333333;
        background-color: #ff9b26;
      }
    }
  }
}

.el-dropdown-menu :deep() {
  width: 160px;
  padding: 0px;
}
</style>
