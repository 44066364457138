<template>
  <div id="app">
      <router-view></router-view>
      <BackTop/>
  </div>
</template>

<script>
const BackTop = () => import("@/components/backTop.vue");
export default {
  components: {
    BackTop,
  },
  created() {
    // console.log(screen.width )
    // if(screen.width<768){ // 768，ipad
    //   this.$router.push('./main/mHome');
    // }
  },
  mounted() {
    // window.onresize = () =>{
    //   if(screen.width<768){
    //     console.log(1);
    //     this.$router.push('/main/mHome');
    //   }else{
    //     console.log(2);
    //     this.$router.push('/main/home');
    //   }
    // }
  }
}
</script>

<style>
*{
  list-style: none;
  margin: 0;
  padding: 0;
}
#app{
  /* min-width: 1200px; */
}
</style>
