<template>
  <div id="main">
        <router-view></router-view>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/footer.vue';
export default {
  components:{Header,Footer},
  computed: {
  },
}
</script>

<style scoped lang="scss">
  #main {
    overflow: auto;
      &::-webkit-scrollbar {
        display:none
      }
    
  }
</style>