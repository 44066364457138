//引入Vue
import Vue from 'vue'
//引入路由
import VueRouter from 'vue-router'
//使用路由
Vue.use(VueRouter)

// import Game from '@/views/Game.vue'
import Home from '@/views/Home.vue';
import Main from '@/views/Main.vue';
import mHome from '@/views/Home_m.vue';

//懒加载
let My404=()=>import('@/views/404.vue')
const routes = [
  {
    path:"/",
    redirect:'/main/mHome'
  },
  {
    path:"/bg1",
    component:mHome,
  },
  {
    path:"/bg2",
    component:mHome,
  },
  {
    path:"/bg3",
    component:mHome,
  },
  {
    path:"/bg4",
    component:mHome,
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
    children:[
      {
        path: 'mHome',
        component:mHome,
      },
      {
        path: 'home',
        component: Home,
      },
    ]
  },
  {
    path:"/*",
    component:My404,
  },
]

//实例化全局路由对象
const router = new VueRouter({
  //路由模式
  // mode: 'history',
  base: process.env.BASE_URL,
  //把routes数组配置进来
  routes
})
//把路由对象暴露出来
export default router