<template>
  <div class="homePage" id="home">
    <Header />
    <full-page :options="options" ref="fullpage">
      <div class="section pcbg1">
        <div class="cover">
          <div class="title">
            <h2>
              <span class="active">火</span>果猫
            </h2>
            <h2>
              GAME
            </h2>
            <h2>
              W<span class="active">E</span>
            </h2>
          </div>
          <div class="keyWords">
            <h2>CREATE
              <span style="--i:0;" data-text="Then.">Then.</span>
              <span style="--i:1;" data-text="Now.">Now.</span>
              <span style="--i:2;" data-text="Forever.">Forever.</span>
            </h2>
          </div>
        </div>
      </div>

      <div class="section pcbg2">
        <!-- banner轮播 -->
        <el-carousel :height="bannerHeight + 'px'" @change="handleSlideChange">
          <el-carousel-item v-for="(item, index) in imgList" :key="item.id">
            <!-- banner图片 -->
            <img :src="item.banner" ref="bannerHeight" @load="imgLoad" style="width: 100%" />
            <!-- 图片详情 -->
            <div class="detial" :class="{ trans: currentIndex === index }">
              <!-- <img :src="item.det" style="width: 80%" class="detLogo " :class="{ trans: currentIndex === index }" /> -->
              <p class="det-txt" :class="{ trans: currentIndex === index }">
                {{ item.tit }}
              </p>
              <img class="det-ent" :class="{ trans: currentIndex === index }" :src="item.way"
                style="width: 30%; cursor: pointer" @click="useWay()" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="section pcbg3">

      </div>

      <div class="section pcbg4">

      </div>
    </full-page>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
export default {
  components: { Header },
  computed: {
    // ...之前的计算属性保持不变
    numStore: {
      get() {
        return this.$store.state.num;
      }
    }
  },
  watch: {
    // 监听 numFromStore 的变化
    numStore(newVal, oldVal) {
      this.scrollToSection(newVal)
    }
  },
  data() {
    return {
      options: {
        // paddingTop: "60px",
        afterLoad: this.afterLoad,
        scrollBar: false,
        menu: '#menu',
        navigation: true,
        anchors: ['pcbg1', 'pcbg2', 'pcbg3', 'pcbg4']
      },
      currentIndex: 0,
      bannerHeight: "",
      imgList: [
        {
          banner: require("@/assets/b1.png"),
          det: require("@/assets/img/det1.png"),
          way: require("@/assets/img/down.png"),
          id: 1,
          tit:'星际异兽'
        },
        {
          banner: require("@/assets/b2.png"),
          det: require("@/assets/img/det2.png"),
          way: require("@/assets/img/down.png"),
          id: 2,
          tit:'敬请期待'
        },
      ],
      smBgList: [
        {
          banner: require("@/assets/img/sm-ban.png"),
          info: "开启全新副本加入我们",
          id: 1,
        },
        {
          banner: require("@/assets/img/sm-ban2.png"),
          info: "升级最新玩法改变你对游戏的看法",
          id: 2,
        },
      ],
      infoList: [
        {
          content: "开启全新副本加入我们",
          tit: "产品动态",
          time: "2023-06-29",
          id: 1,
        },
        {
          content: "升级最新玩法改变你对游戏的看法",
          tit: "产品动态",
          time: "2023-06-29",
          id: 2,
        },
        {
          content: "升级最新玩法改变你对游戏的看法",
          tit: "产品动态",
          time: "2023-06-29",
          id: 3,
        },
      ],
      gameList: [
        {
          banner: require("@/assets/img/game1.png"),
          logo: require("@/assets/img/logoItem.png"),
          id: 1,
        },
        {
          banner: require("@/assets/img/game1.png"),
          logo: require("@/assets/img/logoItem.png"),
          id: 2,
        },
        {
          banner: require("@/assets/img/game1.png"),
          logo: require("@/assets/img/logoItem.png"),
          id: 3,
        },
      ],
      aboutList: [
        {
          icon: require("@/assets/img/abu1.png"),
          tit: '玩家中心',
          cus: 'Player Community',
          id: 1,
        },
        {
          icon: require("@/assets/img/abu2.png"),
          tit: '充值中心',
          cus: 'Voucher Center',
          id: 2,
        },
        {
          icon: require("@/assets/img/abu3.png"),
          tit: '客服中心',
          cus: 'Customer Service',
          id: 3,
        },
        {
          icon: require("@/assets/img/abu4.png"),
          tit: '实名认证',
          cus: 'Real Name Recognition',
          id: 4,
        },
      ]
    };
  },
  methods: {
    scrollToSection(sectionIndex) {
      this.$refs.fullpage.api.moveTo(`pcbg${sectionIndex}`, 1)
    },
    // 获取banner高度
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
      });
    },
    useWay() {
      // let res = await this.$http.home.getDown({})
      // console.log("被点击");
      // console.log(res);
      const apkURL = 'http://hgmgame.com/gameapk/longzhu.apk'; // 替换为实际的apk文件链接
      const link = document.createElement('a');
      link.href = apkURL;
      // link.setAttribute('download', 'app.apk');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    goToInfo() {
      console.log("xinwen");
    },
    handleSlideChange(index) {
      this.currentIndex = index; // 更新当前轮播图索引
      // console.log(index);
    },
  },

  mounted() {
    this.imgLoad();
    // 初始化视口大小
    window.addEventListener(
      "resize",
      () => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
        this.imgLoad();
      },
      false
    );
  },
};
</script>

<style scoped lang="scss">
@keyframes displayText {
  0% {
    display: inline-block;
  }

  33.33%,
  100% {
    display: none;
  }
}

@keyframes fontFlash {

  0%,
  10%,
  100% {
    width: 0;
  }

  70%,
  90% {
    width: 100%;
  }
}
.pcbg1 {
  background: url("../assets/b1.png") no-repeat center/cover ;

  .cover {
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .title {
      margin-top: 220px;
      text-align: center;

      .active {
        color: #fe7301;
        filter: drop-shadow(0 0 5px #fe7301) drop-shadow(0 0 25px #fe7301);
        animation: fontFlash 3s linear infinite;
      }

      h2 {
        color: #fff;
        font-size: 3em;
        font-weight: 600;
        margin-top: 20px;
        padding: 0 100px;
      }
    }

    .keyWords {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      background-image: 1inear-gradient(to right, #333 1px, transparent 1px), 1inear-gradient(to bottom, #333 1px, transparent 1px);
      // background-size: 4vh 4vh;

      h2 {
        color: #fff;
        font-size: 3em;
        font-weight: 600;
        margin-top: 20px;
        span {
          position: relative;
          color: rgba(255, 255, 255, 0.1);
          animation: displayText 9s infinite;
          animation-delay: calc(-3s * var(--i));

          &::before {
            content: attr(data-text);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            color: #fe7301;
            border-right: 4px solid #fe7301;
            filter: drop-shadow(0 0 5px #fe7301) drop-shadow(0 0 25px #fe7301);
            animation: fontFlash 3s linear infinite;
          }
        }
      }

    }
  }

}
@keyframes trans {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

#home :deep() {
  overflow: hidden;
  position: relative;

  .section {
    margin-top: 100px;
  }

  

  .trans {
    animation-timing-function: ease;
    animation-name: trans;
    animation-duration: 2s;
  }

  // banner
  .el-carousel {
    background-color: rgb(236, 243, 253);
    position: relative;
    // banner详情

    .detial {
      width: 20%;
      padding: 20px;
      position: absolute;
      top: 20%;
      right: 10%;

      .det-txt {
        font-size: 48px;
        font-weight: bold;
        color: white;
        margin: 10% 0;
      }
    }
  }

  // 新闻
  .news {
    height: 800px;
    width: 100%;
    border: 1px solid rgb(236, 243, 253);
    background: url("@/assets/img/news_bg.jpg") no-repeat center/cover;

    .newscon {
      width: 1900px;
      left: 120px;
      height: 480px;
      top: 50%;
      margin-top: -240px;
      position: relative;

      .news-tit {
        background: url("@/assets/img/newstit.png") no-repeat center/cover;
        width: 262px;
        height: 88px;
        z-index: 10;
        top: 20px;
        position: absolute;
      }

      .news-info {
        background: url("@/assets/img/inf_bg.png") no-repeat center/cover;
        width: 1680px;
        height: 456px;
        z-index: 9;
        right: -5px;
        position: absolute;
        top: 50%;
        margin-top: -228px;

        .news-txt {
          width: 1087px;
          height: 248px;
          position: absolute;
          right: 20px;
          top: 83px;

          .newsItem {
            height: 82px;
            width: 100%;
            position: relative;

            .itemClick {
              height: 68px;
              width: 98%;
              position: absolute;
              left: 14px;
              top: 11px;
              text-decoration: none;

              &:hover>.newsItemTit,
              &:hover>.newsItemCon,
              &:hover>.newsItemTime {
                color: orange;
              }

              &:hover>.newsItemTime {
                opacity: 0;
              }

              &:hover>.newsJian {
                opacity: 1;
                transform: translateX(50px);
                transition: 0.5s;
              }

              .newsItemTit {
                color: #fff;
              }

              .newsItemCon {
                font-size: 0.75em;
                color: #8f9ab0;
              }

              .newsItemTime {
                right: 223px;
                width: 100px;
                height: 30px;
                bottom: 11%;
                color: #8f9ab0;
                font-size: 0.75em;
                position: absolute;
              }

              .newsJian {
                background: url("@/assets/img/news_jian.png") no-repeat;
                width: 60px;
                height: 10px;
                right: 299px;
                bottom: 34%;
                position: absolute;
                opacity: 0;
              }
            }
          }
        }

        .news-ban {
          width: 360px;
          height: 446px;
          position: absolute;
          left: 135px;
          top: -8px;
          cursor: pointer;

          .el-carousel {
            height: 446px;

            .el-carousel__container {
              height: 446px;
            }
          }

          .small-ban {
            .smBgshadow {
              background: rgba(0, 0, 0, 0.4);
              width: 100%;
              height: 50px;
              position: absolute;
              bottom: 0;
              border-radius: 5px;

              &:hover>.smBgTit {
                color: orange;
                transition: 0.6s;
              }

              .smBgTit {
                width: 100%;
                height: 100%;
                text-align: center;
                line-height: 0.875em;
                font-size: 0.875em;
                color: #e6ebf4;
                top: 8px;
                position: absolute;
              }
            }

            // 修改指示灯样式
            .el-carousel__indicators,
            .el-carousel__indicators--horizontal {

              .el-carousel__indicator,
              .el-carousel__indicator--horizontal {
                .el-carousel__button {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }
  }

  // 游戏
  .game {
    background: url("@/assets/img/game_bg.png") no-repeat center/cover;
    width: 100%;
    height: 1033px;
    overflow: hidden;
    z-index: 2;

    .game-con {
      width: 100%;
      height: 620px;
      margin: 0 auto;
      position: relative;
      top: 200px;
      text-align: center;

      .game-box {
        width: 270px;
        height: 573px;
        margin-right: 20px;
        margin-left: 20px;
        display: inline-block;
        overflow: hidden;

        .game-item {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          position: relative;
          width: 270px;
          height: 573px;
          display: inline-block;
          overflow: hidden;

          .gameLogo {
            position: absolute;
            width: 149px;
            height: 154px;
            top: 170px;
            margin-left: -75px;
            left: 50%;
            opacity: 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }

          .gamecover {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            height: 573px;
            width: 270px;
            background-color: rgba(0, 0, 0, 0.7);
          }

          .gameEnter {
            position: absolute;
            width: 130px;
            height: 34px;
            top: 475px;
            margin-left: -65px;
            left: 50%;
            opacity: 0;
            background: url("@/assets/img/game_button.png") no-repeat center/cover;
          }
        }

        &:hover>.game-item {
          transform: translateY(-40px);
          transition: 0.2s linear;
        }

        &:hover>.game-item .gameLogo {
          transition: 0.5s linear;
          opacity: 1;
        }

        &:hover>.game-item .gamecover {
          opacity: 1;
        }

        &:hover>.game-item .gameEnter {
          transform: translateY(-80px);
          transition: 0.5s linear;
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }

  // 关于我们
  .aboutUs {
    background: url("@/assets/img/more-bg.png") no-repeat center/cover;
    width: 100%;
    height: 523px;

    .aboutCon {
      width: 1200px;
      padding: 140px 0;
      margin: auto;
      font-size: 0;
      display: flex;
      justify-content: space-between;

      .aboutItem {
        border: 3px solid #8b8b8b;
        display: inline-block;
        width: 280px;
        height: 300px;
        font-size: 14px;
        background: #fff;
        transition: all .3s;
        text-align: center;

        &:hover {
          transition: 0.3s;
          background-color: orange;
          transform: scale(1.02);
        }

        &:hover>.itemTit {
          transition: 0.3s;
          color: white;
        }

        &:hover>.itemCus {
          transition: 0.3s;
          color: white;
        }

        .itemImg {
          height: 51px;
          width: 51px;
          margin: auto;
          margin-top: 68px;
          background-size: 100% 100%;
          background-position: 50%;
          background-repeat: no-repeat;
        }

        .itemTit {
          font-size: 24px;
          font-weight: 700;
          margin-top: 46px;
          color: #848484;
        }

        .itemCus {
          margin-top: 8px;
          font-size: 16px;
          color: #848484;
        }
      }
    }
  }
}
</style>