//引入axios
import axios from 'axios'
//引入ElmentUI消息框
import {Message} from 'element-ui'
//引入路由对象
import router from '@/router'

//配置
let newaxios = axios.create({
    //配置前缀路径,以后真正发请求时直接用/代表http://localhost:3000
    baseURL:process.env.NODE_ENV === "production"?'':'http://hgmgame.com:8080',
    // baseURL:"process.env.VUE_APP_url",
    //配置超时,如果服务器超过3s未响应，则直接报错,给客户一个回馈
    timeout:300000
})

//axios提供了两个拦截器，一个是拦截请求，一个是拦截响应
//拦截请求：再发送请求之前会被拦截，我能够在拦截中写自己的业务
newaxios.interceptors.request.use(config=>{
    //在请求对象中的请求头属性中挂在token
    // config.headers["lap-user-token"]=localStorage.token
    //放行
    // console.log("config",res);
    return config
},err=>{
    console.error(error);
    return err
})

//拦截响应：服务器响应数据了，拿到数据后立马被拦截
newaxios.interceptors.response.use(config=>{
    console.log("响应后",config.data);
    return config.data
},err=>{
    console.log(err);
    // if ((err.response.code==401)) {
    //     Message({
    //         message:"权限过期,请登录",
    //         type:"warning"
    //     })
    //     //跳转
    //     router.push("/login")
    // }
})
export default newaxios;