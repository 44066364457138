<template>
  <div class="homePage_m">
    <Header></Header>
    <full-page :options="options" ref="fullpage">
      <div class="section bg1">
        <div class="cover">
          <div class="title">
            <h2>
              <span class="active">火</span>果猫
            </h2>
            <h2>
              GAME
            </h2>
            <h2>
              W<span class="active">E</span>
            </h2>
          </div>
          <div class="keyWords">
            <h2>CREATE
              <!-- <span style="--i:0;" data-text="Then.">Then.</span> -->
              <span style="--i:0;" data-text="Now.">Now.</span>
              <!-- <span style="--i:2;" data-text="Forever.">Forever.</span> -->
            </h2>
          </div>
        </div>

      </div>

      <div class="section bg2">
        <div class="block" style="margin:20px auto 0 auto;">
          <el-carousel trigger="hover" :height="(bannerHeight - 100) + 'px'" @change="handleSlideChange" :interval="5000"
            style="width: 90%;margin: 0 auto;border-top-left-radius: 10px;border-top-right-radius: 10px;"
            arrow="always">
            <el-carousel-item v-for="(item, index) in imgList" :key="item.id">
              <!-- banner图片 -->
              <img class="bannerBg" :src="item.banner" ref="bannerHeight" @load="imgLoad" />
              <!-- 动画图片 -->
              <div class="detial">
                <img :src="item.det" style="width: 100%" class="detLogo" :class="{ trans: currentIndex === index }" />
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="info">
            <div class="game_title">
              <div class="chn">
                {{ gameList[currentIndex].chn }}
              </div>
              <div class="eng">
                {{ gameList[currentIndex].eng }}
              </div>
              <div class="dsc">
                {{ gameList[currentIndex].dsc }}
              </div>
            </div>
            <div class="game_down">
              <div class="down_card" @click="useWay(gameList[currentIndex].url)">
                <i class="el-icon-download iconFlash"></i>
              </div>
              <div class="down_text">
                Down load
              </div>

            </div>
            <div class="game_operate">
              <div>
                <el-button icon="el-icon-share" circle
                  style="font-size: 24px;border-color: #000;color: #000"></el-button>
              </div>

              <div>
                <el-button icon="el-icon-question" circle
                  style="font-size: 24px;border-color: #000;color: #000"></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section bg3">
        <div class="tab">
          <a href="javascript:;" v-for="(ite, inde) in tabsList" :key="inde" @click="changeTab(inde)"
            :class="{ tab_active: tabAct == inde }">{{ ite.tit }}</a>
        </div>

        <div>
          <div class="about_box" v-if="tabAct == 0">
            <img src="../assets/jianjie.png" alt="" style="width: 100%;" />
            <div class="txt">
              <div class="pic">
                <img src="../assets/logo.png" alt="" style="width: 30%;">
              </div>

              <p>火果猫科技有限公司成立于2023年，致力于打造富有创意与深度的互动娱乐体验，让每一位玩家都能在我们的游戏中找到属于自己的故事。</p>
              <p>
                我们坚持原创，勇于尝试新技术、新玩法，旨在打破传统游戏模式的界限。从引人入胜的剧情设计到沉浸式的视听效果，从独特的游戏机制到无缝的用户体验，【火果猫】致力于在每一个细节上超越玩家的期待。
              </p>
              <p>
                在快节奏的游戏市场中，我们选择了“少而精”的路线。每一款游戏的开发都是一段漫长而精细的旅程，我们倾注时间与心血，确保每一作都能成为值得玩家珍藏的艺术品。无论是独立游戏还是小型联网游戏，我们都追求极致的品质与创新。
              </p>
            </div>
          </div>

          <div class="about_box" v-if="tabAct == 1">
            <img src="../assets/fazhan.png" alt="" style="width: 100%;" />
            <el-timeline style="margin-top: 20px;">
              <el-timeline-item v-for="(activity, index) in timeLine" :key="index" :timestamp="activity.timestamp" :color="activity.color">
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </div>

          <div class="about_box" v-if="tabAct == 2">
            <div>
              <img src="../assets/wenhua.png" alt="" style="width: 100%;" />
            </div>
            <div class="yuanJing">
              <p style="text-align: left;">超越现实边界</p>
              <br>
              <p style="text-align: right;">点燃梦想火花</p>
            </div>
          </div>
        </div>
      </div>

      <div class="section bg4">
        <div class="contact_box">
          <div class="contact_item" v-for="(item, index) in contactList" :key="index">
            <div class="contact_out">
              <div class="contact_icon">
                <i :class="item.icon" style="color: #fe7301;font-size: 30px;"></i>
              </div>
              <div class="contact_main">
                <div class="c_main_tit">
                  {{ item.tit }}
                </div>
                <div class="c_main_txt">
                  <p>{{ item.sec_one }}</p>
                  <p>{{ item.sec_sec }}</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </full-page>
  </div>
</template>

<script>
import Header from '@/components/menu_m/index.vue';
import Footer from '@/components/menu_m/footer.vue';
export default {
  computed: {
    // ...之前的计算属性保持不变
    numStore: {
      get() {
        return this.$store.state.num;
      }
    }
  },
  watch: {
    // 监听 numFromStore 的变化
    numStore(newVal, oldVal) {
      this.scrollToSection(newVal)
      console.log(`num 的值从 ${oldVal} 变为 ${newVal}`);
      // 在这里执行你希望基于 num 变化所做的其他操作
    }
  },
  components: { Header, Footer },
  name: '',
  data() {
    return {
      timeLine: [{
        content: '火果猫工作室成立',
        timestamp: '2023-06'
      }, {
        content: '第一款独立游戏《星际异兽》完成开发',
        timestamp: '2023-12'
      }, {
        content: '新游戏《糖豆跑酷》进行测试开发',
        timestamp: '2024-02'
      },{
        content: '敬请期待后续作品',
        timestamp: '至今',
        color: '#fe7301'
      },  ],
      contactList: [{ tit: '联系地址', sec_one: '重庆市南岸区重庆经济技术开发区大厦10楼1001室', sec_sec: '', icon: 'el-icon-location-information' }, { tit: '联系方式', sec_one: '联系电话：15095948244', sec_sec: '联系邮箱：xiaofeixia@hgmgame.com', icon: 'el-icon-phone-outline' }, { tit: '商务合作', sec_one: '微信号码：xiao1935890095', sec_sec: '', icon: 'el-icon-s-custom' }],
      tabAct: 0,
      tabsList: [{ tit: '关于我们' }, { tit: '公司历程' }, { tit: '企业文化' }],
      active: 1,
      currentIndex: 0,
      bannerHeight: "",
      imgList: [
        {
          banner: require("@/assets/pro1.png"),
          det: require("@/assets/pro_act.png"),
          id: 1,
        },
        {
          banner: require("@/assets/pro2.png"),
          det: require("@/assets/pro2_act.png"),
          id: 2,
        }
      ],
      gameList: [
        {
          chn: '星际异兽',
          eng: 'Interstellar beasts',
          url: 'http://hgmgame.com/gameapk/longzhu.apk',
          dsc: `一款融合策略与角色扮演的奇幻卡牌游戏。探索神秘大陆，解锁独特技能，组建英雄联盟。挑战史诗级Boss，解开古老谜题，拯救濒临崩溃的幻想世界。`
        },
        {
          chn: '敬请期待',
          eng: 'Stay Tuned',
          url: '',
          dsc: `敬请期待。。。`
        }
      ],
      show: false,
      options: {
        // paddingTop: "60px",
        afterLoad: this.afterLoad,
        scrollBar: false,
        menu: '#menu',
        navigation: true,
        anchors: ['bg1', 'bg2', 'bg3', 'bg4']
      }
    }
  },
  methods: {
    useWay(url) {
      if (url == '') {
        return
      }
      const apkURL = url; // 替换为实际的apk文件链接
      const link = document.createElement('a');
      link.href = apkURL;
      // link.setAttribute('download', 'app.apk');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    scrollToSection(sectionIndex) {
      this.$refs.fullpage.api.moveTo(`bg${sectionIndex}`, 1)
    },
    showPopup() {
      this.show = true;
    },
    // 获取banner高度
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
      });
    },
    handleSlideChange(index) {
      this.currentIndex = index; // 更新当前轮播图索引
      // console.log(index);
    },
    changeTab(num) {
      this.tabAct = num
    }
  },
  mounted() {
    console.log(this.$refs.fullpage.api);
    this.imgLoad();
    // 初始化视口大小
    window.addEventListener(
      "resize",
      () => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
        this.imgLoad();
      },
      false
    );
  },
}
</script>
<style lang="scss" scoped>
.section {
  margin-top: 60px;
}

// 动画
@keyframes yuanjing {

  0% {
    color: #da3c3c;
  }

  15% {
    color: #da8433;
  }

  30% {
    color: #f8df00f8;
  }

  45% {
    color: #2cda1c;
  }

  60% {
    color: #afd447;
  }

  75% {
    color: #25cccc;
  }

  90% {
    color: #2553eb;
  }

  100% {
    color: #fe7301;
  }
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

@keyframes trans {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes displayText {
  0% {
    display: inline-block;
  }

  33.33%,
  100% {
    display: none;
  }
}

@keyframes fontFlash {

  0%,
  10%,
  100% {
    width: 0;
  }

  70%,
  90% {
    width: 100%;
  }
}

// section
.bg1 {
  background-image: url("../assets/bg1.jpg");

  .cover {
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;

    .title {
      margin-top: 150px;
      text-align: center;

      .active {
        color: #fe7301;
        filter: drop-shadow(0 0 5px #fe7301) drop-shadow(0 0 25px #fe7301);
        animation: fontFlash 3s linear infinite;
      }

      h2 {
        color: #fff;
        font-size: 3em;
        font-weight: 600;
        padding: 0 100px;
      }
    }

    .keyWords {
      display: flex;
      align-items: center;
      text-align: center;
      background-image: 1inear-gradient(to right, #333 1px, transparent 1px), 1inear-gradient(to bottom, #333 1px, transparent 1px);
      // background-size: 4vh 4vh;

      h2 {
        color: #fff;
        font-size: 3em;
        font-weight: 600;
        padding: 0 50px;

        span {
          position: relative;
          color: rgba(255, 255, 255, 0.1);
          animation: displayText 9s infinite;
          animation-delay: calc(-3s * var(--i));

          &::before {
            content: attr(data-text);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            color: #fe7301;
            border-right: 4px solid #fe7301;
            filter: drop-shadow(0 0 5px #fe7301) drop-shadow(0 0 25px #fe7301);
            animation: fontFlash 3s linear infinite;
          }
        }
      }

    }
  }

}

.bg2 {
  width: 100%;
  background: url("../assets/geziBG.png");

  .bannerBg {
    width: 100%;
  }

  .detial {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .info {
    width: 80%;
    margin: 0 auto;
    height: 220px;
    background-color: #fff;
    box-shadow: 0 2px 7px 0 #ffffff;
    padding: 0 5%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    overflow: hidden;
    // display: flex;
    // justify-content: space-between;

    .game_title {

      .chn {
        font-size: 1.6rem;
        font-weight: bold;
        margin-top: 20px;
      }

      .eng {
        font-size: 1rem;
        color: #fe7301;
      }

      .dsc {
        margin-top: 10px;
        font-size: 0.8rem;
        padding-right: 10px;
        width: 95%;
        max-height: 66px;
        overflow: scroll;
      }
    }

    .game_down {
      position: absolute;
      top: 0px;
      right: 20px;

      .down_card {
        .iconFlash {
          font-size: 24px;
          animation: bounce 1s ease-in-out infinite;
        }

        margin-top: 15px;
        width: 70px;
        height: 35px;
        border-radius: 8px;
        border-top-right-radius: 0px;
        border:1px solid#475669;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .down_text {
        font-size: 10px;
        text-align: right;
      }
    }

    .game_operate {
      padding-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
  }

  .trans {
    animation-timing-function: ease;
    animation-name: trans;
    animation-duration: 3s;
  }
}

.bg3 {
  width: 100%;
  background: url("../assets/geziBG.png");

  .tab {
    margin: 20px auto;
    padding: .2rem 0;
    width: 90%;
    box-align: center;
    z-index: 1;
    display: flex;
    justify-content: space-around;

    a {
      text-align: center;
      padding: 10px 0;
      width: 80px;
      display: block;
      font-size: 12px;
      color: #ffffff;
      border: .01rem solid #ffffff;
      box-sizing: border-box;
      margin: 0 auto;
    }

    .tab_active {
      border: transparent;
      background-color: #fe7301
    }
  }

  .about_box {
    width: 88%;
    margin: 0 auto;
    border-radius: 5px;
    background-color: #fff;
    padding: 1%;
    margin-bottom: 20px;

    .yuanJing {
      padding: 20px 0;
      font-weight: bold;
      font-family: 'YouYuan';
      position: relative;
      letter-spacing: 10px;
      text-transform: uppercase;
      line-height: 0.4rem;
      outline: none;
      font-size: 18px;
      -webkit-box-reflect: below 1px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
      animation: yuanjing 5s infinite linear;
    }

    .txt {
      width: 100%;

      .pic {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 10px;
        color: #333;
        font-size: 11px;
        line-height: 22px;

      }
    }
  }
}

.bg4 {
  background: url("../assets/geziBG.png");

  .contact_box {
    width: 88%;
    margin: 0 auto;
    padding: 1%;

    .contact_item {
      margin-top: 20px;
      width: 100%;
      height: 80px;

      .contact_out {
        display: flex;
        align-items: center;
        justify-content: left;

        .contact_icon {
          width: 60px;
          height: 60px;
          background-color: #fff;
          border-radius: 5px;
          text-align: center;
          line-height: 75px;
          margin: 0 30px;
        }

        .contact_main {
          color: #fff;

          .c_main_tit {
            font-weight: bold;
          }

          .c_main_txt {
            font-size: 12px;
          }
        }
      }
    }
  }
}

// 公共组件样式
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

:deep(.el-carousel__arrow) {
  font-size: 1.75rem !important;
  color: #fe7301 !important;
}

:deep(.el-carousel__button) {
  width: 1.875rem !important;
  height: 1.125rem !important;
  // background-color: #fe7301;
}
</style>