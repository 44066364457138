import Vue from 'vue'
import App from './App.vue'
//引入路由对象
import router from './router'
import store from './store'
//引入UI
import ElementUI from 'element-ui';
// 改变全局颜色
import './element-variables.scss'
// import '@/utils/rem'
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(VueFullPage);
Vue.use(ElementUI);
Vue.use(Vant);
//引入http
import http from '@/http/http.js'
//把http模块注入到VUE原型链中（配置全局属性）
Vue.prototype.$http=http
Vue.config.productionTip = false

new Vue({
  //注入到Vue实例，意味着我们会今后可以在任意组件中通过this.$xx来获取路由对象
  router,
  store,
  render: h => h(App)
}).$mount('#app')

