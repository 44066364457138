import axios from "@/http/axios";

// 获取最新实验
function getDown(params){
    return axios({
        url:"/gameapk/longzhu",
        method:"get",
        params
    })
}



export default { getDown };