<template>
  <div id="header">
    <div class="logo">
      <van-image width="2rem" height="3rem" fit="cover" :src="logoImg" />
    </div>
    <div class="nav">
      <van-icon name="wap-nav" size="2rem" color="#fe7301" @click="showPopup" />
    </div>

    <van-popup v-model="show" position="right" :style="{ height: '100%', width: '60%' }">
      <div class="nav-box">
        <div class="nav-item">
          <van-image width="2rem" height="3rem" fit="cover" :src="logoImg" />
          <van-icon name="cross" size="2rem" color="#fe7301" @click="show = false" style="position: absolute;right: 1rem;"/>
        </div>
        <template v-for="(item,index) in menuList">
          <div class="nav-item" @click="toSection(index)" :class="current == index ? 'nav-item-active' :''">
            <div class="nav-tit" :class="current == index ? 'nav-tit-active' :''">{{ item.tit }}</div>
          </div>
        </template>
        
      </div>
    </van-popup>
  </div>
</template>

<script>

export default {
  data() {
    return {
      show: false,
      logoImg: require("@/assets/logo.png"),
      menuList:[{id:1,tit:'首页'},{id:2,tit:'产品信息'},{id:3,tit:'关于我们'},{id:4,tit:'联系我们'}],
      current:0
    };
  },
  computed: {},
  methods: {
    showPopup() {
      this.show = true;
    },
    toSection(num){
      let realNum = num + 1
      this.current = num
      this.show = false;
      this.$store.commit('updateSection', realNum);
    }
  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.nav-tit-active{
  color: #fe7301;
}
.nav-item-active{
  border-left: 4px solid #fe7301;
}
#header {
  z-index: 9999;
  height: 60px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .logo {
    width: 50px;
    margin-top: 5px;
  }

  .nav {
    position: absolute;
    right: 1rem;
  }

  .nav-box{
    width: 100%;
    .nav-item{
      height: 60px;
      width: 100%;
      border-top: 1px solid #e6e7eb;
      font-weight: bold;
      color: #3d424d;
      line-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:last-child{
        border-bottom: 1px solid #e6e7eb;
      }
    }
  }
}
</style>